<template>
    <div class="section section--small">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-7 pr-lg-4 mb-lg-0 mb-4">
                    <!-- Form Messages -->
                    <!-- Sem sa budu vypisovat vsetky chyby, ktore sa udiali vo formulari -->
                    <!-- Inicializuje ich centralna funkcia validateForm -->

                    <div>
                        <div
                            v-for="(message, index) in loginMessages"
                            :key="`loginMessage${index}`"
                        >
                            <Notification
                                type="error"
                                :text="message"
                            ></Notification>
                            <!--                    <span>{{ message }}</span>-->
                        </div>
                    </div>

                    <!-- /Login Messages -->

                    <div
                        class="mb-4"
                        v-if="formMessages && formMessages.length > 0"
                    >
                        <!-- Form Messages -->
                        <!-- Sem sa budu vypisovat vsetky chyby, ktore sa udiali vo formulari -->
                        <!-- Inicializuje ich centralna funkcia validateForm -->
                        <Notification
                            type="error"
                            :messages="formMessages"
                        ></Notification>
                        <!-- /Form Messages -->
                    </div>

                    <!-- Osobne udaje -->
                    <!-- row -->
                    <h2 class="h4 mb-3">Osobné údaje</h2>
                    <div class="row">
                        <div class="col-sm-6 mb-3">
                            <label for="name" class="sr-only">Meno*</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="personalInformation.name"
                                :disabled="isUserLoggedIn"
                                id="name"
                                name="name"
                                placeholder="Meno"
                                autocomplete="given-name"
                            />
                        </div>
                        <div class="col-sm-6 mb-3">
                            <label for="surname" class="sr-only"
                                >Priezvisko*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="personalInformation.surname"
                                :disabled="isUserLoggedIn"
                                id="surname"
                                name="surname"
                                placeholder="Priezvisko"
                                autocomplete="family-name"
                            />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="email" class="sr-only">E-mail*</label>
                            <input
                                type="text"
                                class="form-control"
                                @blur="liveValidateEmail"
                                v-model="personalInformation.email"
                                :disabled="isUserLoggedIn"
                                id="email"
                                name="email"
                                placeholder="E-mail"
                            />

                            <!-- Ak su dake spravy ulozene v email messages a zaroven nieje uzivatel prihlaseny -->
                            <div v-if="emailMessages.length && !isUserLoggedIn">
                                <span
                                    v-for="(message, index) in emailMessages"
                                    :key="`emailMessages${index}`"
                                >
                                    {{ message }}
                                </span>

                                <!-- Button pre opatovne prihlasenie sa, ukaze sa iba v pripade, ze mail je nevyhovujuci -->
                                <button
                                    v-if="emailStatus == 'invalid'"
                                    @click="registerNew = false"
                                >
                                    Prihlasiť sa
                                </button>
                            </div>
                        </div>

                        <!--            <div class="row mb-5" v-if="registerNew">-->
                        <!--                <div class="col-md-6">-->
                        <!--                    <label for="password">Zadajte heslo (min. 6 znakov)*</label>-->
                        <!--                    <input type="text" class="form-control" v-model="personalInformation.password" id="password" name="password" placeholder="Zadajte heslo">-->
                        <!--                </div>-->
                        <!--                <div class="col-md-6">-->
                        <!--                    <label for="password-confirm">Potvrďte heslo*</label>-->
                        <!--                    <input type="text" class="form-control" v-model="personalInformation.passwordConfirm" id="password-confirm" name="password-confirm" placeholder="Potvrdte heslo">-->
                        <!--                </div>-->
                        <!--            </div><div class="row mb-5" v-if="registerNew">-->
                        <!--                <div class="col-md-6">-->
                        <!--                    <label for="password">Zadajte heslo (min. 6 znakov)*</label>-->
                        <!--                    <input type="text" class="form-control" v-model="personalInformation.password" id="password" name="password" placeholder="Zadajte heslo">-->
                        <!--                </div>-->
                        <!--                <div class="col-md-6">-->
                        <!--                    <label for="password-confirm">Potvrďte heslo*</label>-->
                        <!--                    <input type="text" class="form-control" v-model="personalInformation.passwordConfirm" id="password-confirm" name="password-confirm" placeholder="Potvrdte heslo">-->
                        <!--                </div>-->
                        <!--            </div>-->

                        <div class="col-12 mb-3">
                            <label for="telephone" class="sr-only"
                                >Telefón*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="personalInformation.telephone"
                                :disabled="isUserLoggedIn"
                                id="telephone"
                                name="telephone"
                                placeholder="Telefón"
                            />
                        </div>
                        <div class="col-sm-8 mb-3">
                            <label for="city" class="sr-only">Mesto*</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="personalInformation.city"
                                :disabled="isUserLoggedIn"
                                id="city"
                                name="city"
                                placeholder="Mesto"
                            />
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="zip" class="sr-only">PSČ*</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="personalInformation.zip"
                                :disabled="isUserLoggedIn"
                                id="zip"
                                name="zip"
                                placeholder="PSČ"
                                autocomplete="postal-code"
                            />
                        </div>
                        <div class="col-sm-8 mb-3">
                            <label for="street" class="sr-only">Ulica*</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="personalInformation.street"
                                :disabled="isUserLoggedIn"
                                id="street"
                                name="street"
                                placeholder="Ulica"
                            />
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="street-nr" class="sr-only"
                                >Č.domu*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="personalInformation.streetNr"
                                :disabled="isUserLoggedIn"
                                id="street-nr"
                                name="housenumber"
                                placeholder="Č.domu"
                            />
                        </div>
<!--                        <div class="col-12 mb-3">-->
<!--                            <label for="id-card" class="sr-only"-->
<!--                                >Číslo občianskeho preukazu</label-->
<!--                            >-->
<!--                            <input-->
<!--                                type="text"-->
<!--                                class="form-control"-->
<!--                                v-model="personalInformation.idCard"-->
<!--                                :disabled="isUserLoggedIn"-->
<!--                                id="id-card"-->
<!--                                name="id-card"-->
<!--                                placeholder="Číslo občianskeho preukazu"-->
<!--                            />-->
<!--                            <small>-->
<!--                                Vyplnením tohoto údaju urýchlite vybavenie-->
<!--                                objednávky-->
<!--                            </small>-->
<!--                        </div>-->
<!--                        <div class="col-12 mb-3">-->
<!--                            <label for="id-nr" class="sr-only"-->
<!--                            >Rodné číslo</label-->
<!--                            >-->
<!--                            <input-->
<!--                                type="text"-->
<!--                                class="form-control"-->
<!--                                v-model="personalInformation.idNr"-->
<!--                                :disabled="isUserLoggedIn"-->
<!--                                id="id-nr"-->
<!--                                name="id-nr"-->
<!--                                placeholder="Rodné číslo"-->
<!--                            />-->
<!--                            <small>-->
<!--                                Vyplnením tohoto údaju urýchlite vybavenie-->
<!--                                objednávky-->
<!--                            </small>-->
<!--                        </div>-->

                        <!-- kod zakaznika -->
<!--                        <div class="col-12 mb-3">-->
<!--                            <label for="id-nr" class="sr-only"-->
<!--                            >Ref. číslo</label-->
<!--                            >-->
<!--                            <input-->
<!--                                type="text"-->
<!--                                class="form-control"-->
<!--                                v-model="personalInformation.ref_number"-->
<!--                                :disabled="isUserLoggedIn"-->
<!--                                id="ref_number"-->
<!--                                name="id-nr"-->
<!--                                placeholder="Kód zákazníka"-->
<!--                            />-->
<!--                            <small>-->
<!--                                Sem doplňte číselný kód od Vášho známeho, ktorý Vám odporučil nákup Plustelky.-->
<!--                            </small>-->
<!--                        </div>-->
                        <!-- AKCIA zlavovy kod -->
<!--                        <div class="col-12 mb-3">-->
<!--                            <label for="id-nr" class="sr-only"-->
<!--                            >Zľavový kód</label>-->
<!--                            <input-->
<!--                                type="text"-->
<!--                                class="form-control"-->
<!--                                v-model="personalInformation.ref_number"-->
<!--                                id="ref_number"-->
<!--                                name="id-nr"-->
<!--                                placeholder="Zľavový kód"-->
<!--                            />-->
<!--                            <small>-->
<!--                                Sem zadajte Váš zľavový kód-->
<!--                            </small>-->
<!--                        </div>-->
                        <!-- AKCIA BF: NOVA cast - zlavovy kod -->
                        <div class="col-12 mb-3">
                            <label for="id-nr" class="sr-only">Zľavový kód</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="personalInformation.ref_number"
                                id="ref_number"
                                name="id-nr"
                                placeholder="Zľavový kód"
                            />
                            <template v-if="bf_discount">
                                <small style="color: #11AA87;" class="font-weight-bold" v-if="bf_discount && months >= 12">
                                    Váš zľavový kód bude uplatnený v nasledujúcom kroku.
                                </small>
                                <small v-else>
                                    Zľavový kód je možné uplatniť len pri kúpe 12 mesiacov
                                </small>
                            </template>
                            <small v-else>
                                Sem zadajte Váš zľavový kód <span v-if="months >= 12"> (zľavy nie je možné kombinovať)</span>
                            </small>
                        </div>
                    </div>

                    <!-- / OSobne udaje -->

                    <!-- Fakturačná adresa -->
                    <!-- row -->

                    <h2 class="h4 mb-3 mt-5">Fakturačné údaje</h2>
                    <label
                        for="billing-is-same"
                        class="checkbox"
                        v-if="!isUserLoggedIn"
                    >
                        <input
                            v-model="billingIsSame"
                            type="checkbox"
                            name="billing-is-same"
                            id="billing-is-same"
                        />
                        <span class="checkbox-label"
                            >Fakturačné údaje sú rovnaké ako osobné.</span
                        >
                    </label>

                    <div class="row mb-4" v-if="!billingIsSame">
                        <div class="col-sm-6 mb-3">
                            <label for="billing-name" class="sr-only"
                                >Meno*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="billingInformation.name"
                                :disabled="isUserLoggedIn"
                                id="billing-name"
                                name="billing-name"
                                placeholder="Meno"
                            />
                        </div>

                        <div class="col-sm-6 mb-3">
                            <label for="billing-surname" class="sr-only"
                                >Priezvisko*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="billingInformation.surname"
                                :disabled="isUserLoggedIn"
                                id="billing-surname"
                                name="billing-surname"
                                placeholder="Priezvisko"
                            />
                        </div>

                        <div class="col-12 mb-3">
                            <label for="billing-telephone" class="sr-only"
                                >Telefón*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="billingInformation.telephone"
                                :disabled="isUserLoggedIn"
                                id="billing-telephone"
                                name="billing-telephone"
                                placeholder="Telefón"
                            />
                        </div>

                        <div class="col-sm-8 mb-3">
                            <label for="billing-city" class="sr-only"
                                >Mesto*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="billingInformation.city"
                                :disabled="isUserLoggedIn"
                                id="billing-city"
                                name="billing-city"
                                placeholder="Mesto"
                            />
                        </div>

                        <div class="col-sm-4 mb-3">
                            <label for="billing-zip" class="sr-only"
                                >PSČ*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="billingInformation.zip"
                                :disabled="isUserLoggedIn"
                                id="billing-zip"
                                name="billing-zip"
                                placeholder="PSČ"
                            />
                        </div>

                        <div class="col-sm-8 mb-3">
                            <label for="billing-street" class="sr-only"
                                >Ulica*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="billingInformation.street"
                                :disabled="isUserLoggedIn"
                                id="billing-street"
                                name="billing-street"
                                placeholder="Ulica"
                            />
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="billing-street-nr" class="sr-only"
                                >Č.domu*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="billingInformation.streetNr"
                                :disabled="isUserLoggedIn"
                                id="billing-street-nr"
                                name="billing-street-nr"
                                placeholder="Č.domu"
                            />
                        </div>
                    </div>

                    <!-- / Fakturačná adresa -->

                    <!-- Dodacia adresa -->
                    <!-- row -->

                    <h2 class="h4 mb-3 mt-5">Dodacia adresa</h2>
                    <label for="delivery-is-same" class="checkbox">
                        <input
                            v-model="deliveryIsSame"
                            type="checkbox"
                            name="delivery-is-same"
                            id="delivery-is-same"
                        />
                        <span class="checkbox-label"
                            >Dodacie údaje sú rovnaké ako osobné.</span
                        >
                    </label>

                    <div class="row mb-4" v-if="!deliveryIsSame">
                        <div class="col-sm-6 mb-3">
                            <label for="delivery-name" class="sr-only"
                                >Meno*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="deliveryInformation.name"
                                id="delivery-name"
                                name="delivery-name"
                                placeholder="Meno"
                            />
                        </div>

                        <div class="col-sm-6 mb-3">
                            <label for="delivery-surname" class="sr-only"
                                >Priezvisko*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="deliveryInformation.surname"
                                id="delivery-surname"
                                name="delivery-surname"
                                placeholder="Priezvisko"
                            />
                        </div>

                        <div class="col-12 mb-3">
                            <label for="delivery-telephone" class="sr-only"
                                >Telefón*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="deliveryInformation.telephone"
                                id="delivery-telephone"
                                name="delivery-telephone"
                                placeholder="Telefón"
                            />
                        </div>

                        <div class="col-sm-8 mb-3">
                            <label for="delivery-city" class="sr-only"
                                >Mesto*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="deliveryInformation.city"
                                id="delivery-city"
                                name="delivery-city"
                                placeholder="Mesto"
                            />
                        </div>

                        <div class="col-sm-4 mb-3">
                            <label for="delivery-zip" class="sr-only"
                                >PSČ*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="deliveryInformation.zip"
                                id="delivery-zip"
                                name="delivery-zip"
                                placeholder="PSČ"
                            />
                        </div>

                        <div class="col-sm-8 mb-3">
                            <label for="delivery-street" class="sr-only"
                                >Ulica*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="deliveryInformation.street"
                                id="delivery-street"
                                name="delivery-street"
                                placeholder="Ulica"
                            />
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="delivery-street-nr" class="sr-only"
                                >Č.domu*</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="deliveryInformation.streetNr"
                                id="delivery-street-nr"
                                name="delivery-street-nr"
                                placeholder="Č.domu"
                            />
                        </div>
                    </div>

                    <!-- / Dodacia adresa -->
                </div>

                <!-- Sidebar -->
                <div class="col-lg-5 pl-lg-4">
                    <div class="card-wrapper">
                        <CartComponent :step="3"> </CartComponent>
                        <button
                            class="btn btn-primary btn--big btn--full mt-3"
                            @click="validateForm"
                        >
                            {{ buttonContent }}
                            <img
                                src="/img/icons/arrow-right.svg"
                                alt=""
                                class="btn__icon"
                            />
                        </button>
                    </div>
                </div>

                <!-- Krok spat -->
                <div
                    class="col-md-6 col-sm-4 order-sm-3 order-4 align-self-center"
                >
                    <button class="step-back" @click="prevStep">
                        <img
                            src="/img/icons/back.svg"
                            alt=""
                            class="step-back__icon"
                        />
                        <span class="step-back__title">Krok späť</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import jkbCollapse from "../../components/vue/collapse/collapse.vue";
import _debounce from "lodash/debounce";
import axios from "axios";

import CartComponent from "../../components/vue/CartComponent";
import Notification from "../../components/vue/notification/Notification.vue";

//Mixins
import formStep from "../../mixins/formStep";
import { API_URL, DEFAULT_AUTH, JSON_AUTH } from "../../config";

export default {
    mixins: [formStep],
    components: {
        jkbCollapse,
        CartComponent,
        Notification
    },
    data() {
        return {
            // pocet zvolenych mesiacov
            months: this.$store.state.ponuka?.stepState?.vyberBalikov?.bindedMonths,

            // Zakladne nastavenia pre Vuex a Vue router pre spravne fungovanie kroku formulara
            stepName: "osobneUdaje",
            prevStepName: "antenaStep2",
            nextStepName: "antenaStep5",

            //Premenna ktora urcuje ci sa uzivatel pokusa registrovat ako novy /ovlada ci sa ma zobrazit a validovat heslo a potvrdzujuce heslo/
            registerNew: false,

            //Premenna ktora kontroluje, ci sa ma ukazat login sekcia formularu resp ci sa ma ukazat registracna sekcia
            hideLogin: false,

            // Login a Password // pouzite pri logine, na zaciatku kroku
            userLogin: "",
            userPass: "",
            loginMessages: [],
            attemptingLogin: false,

            // Premenna ktorá oznaci ci sa uzivatel overil pomocou API a ci ma formular predvyplnene udaje
            // tato premenna sa pouziva neskor na pripadnu ignoraciu validacie udajov, ktore su prebrate z API a teda nieje ich treba znova validovat (kedze su navyse tieto udaje aj zamknute)
            isUserLoggedIn: false,

            //Objekty ktore obsahuju osobne, fakturacne a dodacie udaje
            personalInformation: {
                name:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.name ?? "",
                surname:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.surname ?? "",
                email:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.email ?? "",
                telephone:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.telephone ?? "",
                street:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.street ?? "",
                streetNr:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.streetNr ?? "",
                city:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.city ?? "",
                zip:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.zip ?? "",
                idNr:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.idNr ?? "",
                idCard:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.idCard ?? "",
                ref_number:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.ref_number ?? "",
                password:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.password ?? "",
                passwordConfirm:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.personalInformation?.passwordConfirm ?? ""
            },
            emailMessages: [],
            emailStatus: "",

            billingInformation: {
                name:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.billingInformation?.name ?? "",
                surname:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.billingInformation?.surname ?? "",
                telephone:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.billingInformation?.telephone ?? "",
                street:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.billingInformation?.street ?? "",
                streetNr:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.billingInformation?.streetNr ?? "",
                city:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.billingInformation?.city ?? "",
                zip:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.billingInformation?.zip ?? ""
            },
            billingIsSame: true, //ak je true, tak sa fakturacne udaje vzdy rovnaju tym, ktore su zadane v personalInformation

            deliveryInformation: {
                name:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.deliveryInformation?.name ?? "",
                surname:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.deliveryInformation?.surname ?? "",
                telephone:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.deliveryInformation?.telephone ?? "",
                street:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.deliveryInformation?.street ?? "",
                streetNr:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.deliveryInformation?.streetNr ?? "",
                city:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.deliveryInformation?.city ?? "",
                zip:
                    this.$store.state.ponuka?.stepState?.osobneUdaje
                        ?.deliveryInformation?.zip ?? ""
            },
            deliveryIsSame: true, //ak je true, tak sa dorucovacie udaje vzdy rovnaju tym, ktore su zadane v personalInformation

            //Premenna, ktora oznacuje, ci sa komponent uz donacitaval (bypass watcherov, inak hadzu error)
            doneInitializing: false,

            // Tu sa zhromazduju vsetky upozornenia, ak pri validacii udajov pride k chybe napr. "Prosím vyplnte emailovú adresu"
            formMessages: [],
            // Premenna ktora hovori o tom, ci v tomto momente prebieha validacia dat
            formIsValidating: false,
            //Centralna premenna ktora rozhoduje o tom, ci je formular validny (vsetky podmienky sa skontroluju vo funkcii validateForm)
            formIsValid: false,

            // Premenna ktora urcuje ci sa uzivatel zadal spravny zlavovy kod a bude mu odpocitana zlava 10%
            bf_discount: false,
        };
    },

    watch: {
        personalInformation: {
            handler(newValue, oldValue) {
                //Ak je zaskrnute, ze su fakturacne rovnake ako osobne, tak ich naviazeme na seba
                if (this.billingIsSame) {
                    Object.assign(
                        this.billingInformation,
                        this.personalInformation
                    );
                }
                //Ak je zaskrnute, ze su dodacie rovnake ako osobne, tak ich naviazeme na seba
                if (this.deliveryIsSame) {
                    Object.assign(
                        this.deliveryInformation,
                        this.personalInformation
                    );
                }
                // AKCIA: BF -10% -> ak je kod zakaznika/zlavovy kod vyplneny spravnou hodnotou
                this.bf_discount = ((newValue.ref_number === "EURO24") || (newValue.ref_number === "EURO2024"));
            },
            deep: true
        },

        billingIsSame(newValue, oldValue) {
            if (this.doneInitializing) {
                //Ak uzivatel zaskrtne ze su fakturacne rovnake, tak naviazeme objekt osobnych udajov na faktruacne
                if (newValue == true) {
                    Object.assign(
                        this.billingInformation,
                        this.personalInformation
                    );
                } else {
                    this.billingInformation = {
                        name: "",
                        surname: "",
                        telephone: "",
                        street: "",
                        streetNr: "",
                        city: "",
                        zip: ""
                    };
                }

                // Prepne collapse element - otvori/zavrie sekciu formularu
                this.$refs.billingInfo.toggle();
            }
        },

        deliveryIsSame(newValue, oldValue) {
            //console.log('zmenil sa delivery');
            if (this.doneInitializing) {
                //Ak uzivatel zaskrtne ze su dodacie rovnake, tak naviazeme objekt osobnych udajov na faktruacne
                if (newValue == true) {
                    Object.assign(
                        this.deliveryInformation,
                        this.personalInformation
                    );
                } else {
                    this.deliveryInformation = {
                        name: "",
                        surname: "",
                        telephone: "",
                        street: "",
                        streetNr: "",
                        city: "",
                        zip: ""
                    };
                }

                // Prepne collapse element - otvori/zavrie sekciu formularu
                this.$refs.deliveryInfo.toggle();
            }
        }
    },

    computed: {
        ...mapGetters(["getStep"]),
        stepFilledCorrectly() {
            return (
                // Bool
                // tu zhromazdujeme vsetky podmienky, ktore musia byt
                // splnene na to, aby formular mohol pokracovat dalej
                this.formIsValid
            );
        },

        loadingTheme() {
            return this.darkBgAllowed
                ? "loading-pulse__light"
                : "loading-pulse__dark";
        },

        buttonContent() {
            if (this.formIsValidating) {
                return "Overujem údaje...";
            } else return "Ďalší krok";
        },

        loginButtonContent() {
            if (this.attemptingLogin) {
                return "Prihlasujem ...";
            } else return "Prihlásiť sa";
        },

        currentStepStateToSave() {
            //Nastavenia, ktore sa maju ulozit
            return {
                step: "osobneUdaje",
                state: {
                    //location: this.location
                    personalInformation: this.personalInformation,
                    billingInformation: this.billingInformation,
                    billingIsSame: this.billingIsSame,
                    deliveryInformation: this.deliveryInformation,
                    deliveryIsSame: this.deliveryIsSame,
                    isUserLoggedIn: this.isUserLoggedIn,
                    registerNew: this.registerNew,
                    hideLogin: this.hideLogin
                }
            };
        }
    },

    methods: {
        validateTel(value) {
            let noSpaces = value.replace(/\s/g, "");
            if (noSpaces.match(/(\+)*(\d{9,12})/g) && noSpaces.length <= 13)
                return true;
            else return false;
        },

        async validateAddress(address = { city: "", zip: "" }) {
            // Statusy, ktore moze validacna funkcia vratit
            // 'emptyZip' - zip nema ziadnu hodnotu
            // 'emptyCity' - zip nema ziadnu hodnotu
            // 'invalid' - email nevyhovuje - uz bol v DB pouzity
            // 'valid' - email vyhovuje - nebol pouzity v DB
            // 'error' - doslo k chybe

            // Ak zip nema ziadnu hodnotu
            if (address.zip == "") {
                return "emptyZip";
            }

            // Ak city nema ziadnu hodnotu
            else if (address.city == "") {
                return "emptyCity";
            } else {
                // Dummy promise, nahradi sa realnym axios callom
                //axios.post()

                // Simulacia stavov success a fail

                // Success:
                // v pripade ze zadame mesto "true" a zip "12345" vrati sa stav true -> adresa je vyhovujuca
                // v pripade ze zadame "false" a zip "12345" vrati sa stav false -> adresa je nevyhovujuca

                // Fail:
                // ak zadame akukolvek inu hodnotu, tak sa vrati fail (simulacia zlyhania callu na API)
                return true;
                // let data = {};
                // data['method'] = 'GET';
                // data['headers'] = DEFAULT_AUTH;
                // data['url'] = API_URL + "Users/check-user/"+value;
                // this.$http.post('/api/external', data)
                //     .then( data => {
                //         this.staticDeviceValidating = false;
                //         if(data.data.result && data.data.result === 'not found') {
                //             return 'valid';
                //         } else {
                //             return 'invalid';
                //         }
                //     });

                // Ak ma zip aj city daku hodnotu, tak tuto adresu posleme cez API na validaciu, ci sedi nazov mesta s PSC
                // Resolve a Error handling
                try {
                    const result = await dummyPromise;
                    return result;
                } catch (e) {
                    return "error";
                }
            }
        },

        async liveValidateEmail() {
            this.emailMessages = [];
            // Validaciu emailu
            const personalEmailValidation = await this.validateEmail(
                this.personalInformation.email
            );
            this.emailStatus = personalEmailValidation;

            // Prazdny mail
            if (personalEmailValidation == "empty") {
                this.emailMessages.push("Prosím zadajte váš e-mail");
            }
            // Nevyhovujuci email
            else if (personalEmailValidation == "invalid") {
                this.emailMessages.push(
                    "Pod týmto e-mailom je už u nás vytvorený účet, prosím prihláste sa"
                );
            }
            // Nevyhovujuci email
            else if (personalEmailValidation == "not-unique") {
                this.emailMessages.push(
                    "Tento e-mail je už u nás zaregistrovaný, kontaktujte prosím towercom"
                );
            }
            // Error pri kontakte s API - timeout atd.
            else if (personalEmailValidation == "error") {
                this.emailMessages.push(
                    "Pri validácií Vášho e-mailu došlo k chybe, skúste to prosím ešte raz. Ak problém pretrváva, kontaktujte nás."
                );
            }
        },

        async validateEmail(value) {
            // Statusy, ktore moze validacna funkcia vratit
            // 'empty' - email nema správny tvar, alebo je prázdny
            // 'invalid' - email nevyhovuje - uz bol v DB pouzity
            // 'valid' - email vyhovuje - nebol pouzity v DB
            // 'error' - doslo k chybe

            //Skontroluje ci ma mail spravny tvar
            if (value.match(/.+\@.+\.\w+/g)) {
                return "valid";
            } else {
                return "empty";
            }
        },

        async validateForm() {
            // Zapneme premennu, ze sa form pokusa validovat data
            this.formIsValidating = true;
            //Pred kazdou kontrolou vynulujeme vsetky spravy od formulara, aby sa nam neduplikovali a kontrolo
            this.formMessages = [];
            //Pocitadlo chyb vo formulari, na konci formularu sa skontroluje a ak je vacsie ako 0, zamedzi sa odoslaniu udajov na dalsi krok
            let invalidCounter = 0;

            // OSOBNE UDAJE
            if (!this.isUserLoggedIn) {
                //Validacia meno
                if (this.personalInformation.name == "") {
                    this.formMessages.push(
                        "Osobné údaje: Vyplňte prosím Vaše meno"
                    );
                    invalidCounter++;
                }
                // Validacia priezvisko
                if (this.personalInformation.surname == "") {
                    this.formMessages.push(
                        "Osobné údaje: Vyplňte prosím Vaše priezvisko"
                    );
                    invalidCounter++;
                }
                // Validacia telefonneho cisla
                if (!this.validateTel(this.personalInformation.telephone)) {
                    this.formMessages.push(
                        "Osobné údaje: Vyplňte prosím Vaše telefónne číslo"
                    );
                    invalidCounter++;
                }
                // Validaciu emailu
                const personalEmailValidation = await this.validateEmail(
                    this.personalInformation.email
                );
                // Prazdny mail
                if (personalEmailValidation == "empty") {
                    this.formMessages.push(
                        "Osobné údaje: Prosím zadajte váš e-mail"
                    );
                    invalidCounter++;
                }
                // Nevyhovujuci email
                else if (personalEmailValidation == "invalid") {
                    this.formMessages.push(
                        "Osobné údaje: Tento e-mail je už u nás zaregistrovaný"
                    );
                    invalidCounter++;
                }
                // Error pri kontakte s API - timeout atd.
                else if (personalEmailValidation == "error") {
                    this.formMessages.push(
                        "Osobné údaje: Pri validácií Vášho e-mailu došlo k chybe, skúste to prosím ešte raz. Ak problém pretrváva, kontaktujte nás."
                    );
                    invalidCounter++;
                }

                // Validacia ulice
                if (this.personalInformation.street == "") {
                    this.formMessages.push(
                        "Osobné údaje: Vyplňte prosím názov ulice"
                    );
                    invalidCounter++;
                }

                // Validacia cisla domu
                if (this.personalInformation.street == "") {
                    this.formMessages.push(
                        "Osobné údaje: Vyplňte prosím číslo domu "
                    );
                    invalidCounter++;
                }

                // Validacia Mesta a PSC
                const cityZip = {
                    city: this.personalInformation.city,
                    zip: this.personalInformation.zip
                };
                const personalCityValidation = await this.validateAddress(
                    cityZip
                );
                // Prazdne mesto
                if (personalCityValidation == "emptyCity") {
                    this.formMessages.push(
                        "Osobné údaje: Prosím zadajte názov Mesta"
                    );
                    invalidCounter++;
                }
                // Prazdny zip
                if (personalCityValidation == "emptyZip") {
                    this.formMessages.push(
                        "Osobné údaje: Prosím zadajte vaše PSČ"
                    );
                    invalidCounter++;
                }
                // Nevyhovujuci email
                else if (personalCityValidation == "invalid") {
                    this.formMessages.push(
                        "Osobné údaje: PSČ sa nezhoduje s názvom mesta, ktoré ste zadali. Skontrolujte si prosím údaje"
                    );
                    invalidCounter++;
                }
                // Error pri kontakte s API - timeout atd.
                else if (personalCityValidation == "error") {
                    this.formMessages.push(
                        "Osobné údaje: Pri validácií Vášho mesta došlo k chybe, skúste to prosím ešte raz. Ak problém pretrváva, kontaktujte nás."
                    );
                    invalidCounter++;
                }

                // FAKTURACNE UDAJE
                // Ak je vypnute, ze su fakturacne udaje zhodne s osobnymi, validuj samostatne fakturacne udaje
                // zaroven musi platit, ze pouzivatel nezobral udaje z ineho API
                if (!this.deliveryIsSame) {
                    //Validacia meno
                    if (this.billingInformation.name == "") {
                        this.formMessages.push(
                            "Fakturačné údaje: Vyplňte prosím Vaše meno"
                        );
                        invalidCounter++;
                    }
                    // Validacia priezvisko
                    if (this.billingInformation.surname == "") {
                        this.formMessages.push(
                            "Fakturačné údaje: Vyplňte prosím Vaše priezvisko"
                        );
                        invalidCounter++;
                    }
                    // Validacia telefonneho cisla
                    if (!this.validateTel(this.billingInformation.telephone)) {
                        this.formMessages.push(
                            "Fakturačné údaje: Vyplňte prosím Vaše telefónne číslo"
                        );
                        invalidCounter++;
                    }
                    // Validaciu emailu
                    const billingEmailValidation = await this.validateEmail(
                        this.billingInformation.email
                    );
                    // Prazdny mail
                    if (billingEmailValidation == "empty") {
                        this.formMessages.push(
                            "Fakturačné údaje: Prosím zadajte váš e-mail"
                        );
                        invalidCounter++;
                    }
                    // Nevyhovujuci email
                    else if (billingEmailValidation == "invalid") {
                        this.formMessages.push(
                            "Fakturačné údaje: Tento e-mail je už u nás zaregistrovaný"
                        );
                        invalidCounter++;
                    }
                    // Error pri kontakte s API - timeout atd.
                    else if (billingEmailValidation == "error") {
                        this.formMessages.push(
                            "Fakturačné údaje: Pri validácií Vášho e-mailu došlo k chybe, skúste to prosím ešte raz. Ak problém pretrváva, kontaktujte nás."
                        );
                        invalidCounter++;
                    }
                }
                // END osobne udaje

                // *************************
                // *************************
                // FAKTURACNE UDAJE
                // *************************
                // *************************
                // Ak je vypnute, ze su fakturacne udaje zhodne s osobnymi, validuj samostatne fakturacne udaje
                // zaroven musi platit, ze pouzivatel nezobral udaje z ineho API
                if (!this.billingIsSame && !this.isUserLoggedIn) {
                    //Validacia meno
                    if (this.billingInformation.name == "") {
                        this.formMessages.push(
                            "Fakturačné údaje: Vyplňte prosím Vaše meno"
                        );
                        invalidCounter++;
                    }
                    // Validacia priezvisko
                    if (this.billingInformation.surname == "") {
                        this.formMessages.push(
                            "Fakturačné údaje: Vyplňte prosím Vaše priezvisko"
                        );
                        invalidCounter++;
                    }
                    // Validacia telefonneho cisla
                    if (!this.validateTel(this.billingInformation.telephone)) {
                        this.formMessages.push(
                            "Fakturačné údaje: Vyplňte prosím Vaše telefónne číslo"
                        );
                        invalidCounter++;
                    }

                    // Validacia ulice
                    if (this.billingInformation.street == "") {
                        this.formMessages.push(
                            "Fakturačné údaje: Vyplňte prosím názov ulice"
                        );
                        invalidCounter++;
                    }

                    // Validacia cisla domu
                    if (this.billingInformation.street == "") {
                        this.formMessages.push(
                            "Fakturačné údaje: Vyplňte prosím číslo domu "
                        );
                        invalidCounter++;
                    }

                    // Validacia Mesta a PSC
                    const cityZip = {
                        city: this.billingInformation.city,
                        zip: this.billingInformation.zip
                    };
                    const billingCityValidation = await this.validateAddress(
                        cityZip
                    );
                    // Prazdne mesto
                    if (billingCityValidation == "emptyCity") {
                        this.formMessages.push(
                            "Fakturačné údaje: Prosím zadajte názov Mesta"
                        );
                        invalidCounter++;
                    }
                    // Prazdny zip
                    if (billingCityValidation == "emptyZip") {
                        this.formMessages.push(
                            "Fakturačné údaje: Prosím zadajte vaše PSČ"
                        );
                        invalidCounter++;
                    }
                    // Nevyhovujuci email
                    else if (billingCityValidation == "invalid") {
                        this.formMessages.push(
                            "Fakturačné údaje: PSČ sa nezhoduje s názvom mesta, ktoré ste zadali. Skontrolujte si prosím údaje"
                        );
                        invalidCounter++;
                    }
                    // Error pri kontakte s API - timeout atd.
                    else if (billingCityValidation == "error") {
                        this.formMessages.push(
                            "Fakturačné údaje: Pri validácií Vášho mesta došlo k chybe, skúste to prosím ešte raz. Ak problém pretrváva, kontaktujte nás."
                        );
                        invalidCounter++;
                    }
                }
                // END FAKTURACNE UDAJE

                // DODACIE UDAJE
                // Ak je vypnute, ze su fakturacne udaje zhodne s osobnymi, validuj samostatne fakturacne udaje
                if (!this.deliveryIsSame) {
                    //Validacia meno
                    if (this.deliveryInformation.name == "") {
                        this.formMessages.push(
                            "Dodacie údaje: Vyplňte prosím Vaše meno"
                        );
                        invalidCounter++;
                    }
                    // Validacia priezvisko
                    if (this.deliveryInformation.surname == "") {
                        this.formMessages.push(
                            "Dodacie údaje: Vyplňte prosím Vaše priezvisko"
                        );
                        invalidCounter++;
                    }
                    // Validacia telefonneho cisla
                    if (!this.validateTel(this.deliveryInformation.telephone)) {
                        this.formMessages.push(
                            "Dodacie údaje: Vyplňte prosím Vaše telefónne číslo"
                        );
                        invalidCounter++;
                    }

                    // Validacia ulice
                    if (this.deliveryInformation.street == "") {
                        this.formMessages.push(
                            "Dodacie údaje: Vyplňte prosím názov ulice"
                        );
                        invalidCounter++;
                    }

                    // Validacia cisla domu
                    if (this.deliveryInformation.street == "") {
                        this.formMessages.push(
                            "Dodacie údaje: Vyplňte prosím číslo domu "
                        );
                        invalidCounter++;
                    }

                    // Validacia Mesta a PSC
                    const cityZip = {
                        city: this.deliveryInformation.city,
                        zip: this.deliveryInformation.zip
                    };
                    const deliveryCityValidation = await this.validateAddress(
                        cityZip
                    );
                    // Prazdne mesto
                    if (deliveryCityValidation == "emptyCity") {
                        this.formMessages.push(
                            "Dodacie údaje: Prosím zadajte názov Mesta"
                        );
                        invalidCounter++;
                    }
                    // Prazdny zip
                    if (deliveryCityValidation == "emptyZip") {
                        this.formMessages.push(
                            "Dodacie údaje: Prosím zadajte vaše PSČ"
                        );
                        invalidCounter++;
                    }
                    // Nevyhovujuci email
                    else if (deliveryCityValidation == "invalid") {
                        this.formMessages.push(
                            "Dodacie údaje: PSČ sa nezhoduje s názvom mesta, ktoré ste zadali. Skontrolujte si prosím údaje"
                        );
                        invalidCounter++;
                    }
                    // Error pri kontakte s API - timeout atd.
                    else if (deliveryCityValidation == "error") {
                        this.formMessages.push(
                            "Dodacie údaje: Pri validácií Vášho mesta došlo k chybe, skúste to prosím ešte raz. Ak problém pretrváva, kontaktujte nás."
                        );
                        invalidCounter++;
                    }
                }

                // Vypneme premennu. ze sa form pokusa validovat data
                this.formIsValidating = false;

                // Vyhodnotenie a posun na dalsi krok
                // Ak došlo k akejkolvek chybe pri validacii formularu, vytiahni uživateľa k error logingu nad formular
                if (invalidCounter > 0) {
                    window.scrollTo(0, 0);
                    this.formIsValid = false;
                } else {
                    this.formIsValid = true;
                    // console.log("form is valid...");
                    this.nextStep();
                    this.$nextTick();
                }
            }
        },
        created() {
            //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
            const stepData = this.getStep("osobneUdaje");
            if (stepData) {
                if (stepData.billingIsSame != null) {
                    this.billingIsSame = stepData.billingIsSame;
                    //console.log(this.billingIsSame);
                }
                if (stepData.deliveryIsSame != null) {
                    this.deliveryIsSame = stepData.deliveryIsSame;
                    //console.log(this.deliveryIsSame);
                }
            }
        },

        mounted() {
            //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
            const stepData = this.getStep("osobneUdaje");
            if (stepData) {
                this.registerNew = stepData.registerNew;
                this.billingIsSame = stepData.billingIsSame;
                this.deliveryIsSame = stepData.deliveryIsSame;
                this.isUserLoggedIn = stepData.isUserLoggedIn;

                if (stepData.personalInformation) {
                    this.personalInformation = stepData.personalInformation;
                }

                // Nastavi fakturacne udaje zo store ak nema ziadne, tak nastavi to iste co je nastavene v personal info
                if (stepData.billingInformation) {
                    Object.assign(
                        this.billingInformation,
                        stepData.billingInformation
                    );
                    //console.log('mam data billing')
                } else {
                    Object.assign(
                        this.billingInformation,
                        this.personalInformation
                    );
                }

                // Nastavi dodacie udaje zo store ak nema ziadne, tak nastavi to iste co je nastavene v personal info
                if (stepData.deliveryInformation) {
                    Object.assign(
                        this.deliveryInformation,
                        stepData.deliveryInformation
                    );
                    //console.log('mam data delivery')
                } else {
                    Object.assign(
                        this.deliveryInformation,
                        this.personalInformation
                    );
                }
            }

            //Prepne stav komponentu na dokoncene nacitavanie (bypass watcherov)
            this.$nextTick(() => {
                this.doneInitializing = true;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.wraper {
    position: relative;
}

.registration-section {
    transition: all 0.3s ease-out;
    position: relative;

    &.hidden {
        position: absolute;
        opacity: 0;
    }
}
</style>
