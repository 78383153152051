<template>
    <div class="section section--small">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-7 pr-lg-4 mb-lg-0 mb-4">
                    <!-- Overenie lokality -->
                    <ConfirmLocation
                        @success=" e => { this.locationSuccess = e; this.showForm = !e;}"
                        :selectedItem="selectedCity"
                    ></ConfirmLocation>
                    <div v-if="showForm" class="mb-2 mt-2">
                            <div class="mb-3">
                                <input type="tel" name="phone" id="js-phone" class="form-control" placeholder="Vaše tel.číslo" >
                            </div>
                            <div>

                                <label class="checkbox">
                                    <input type="checkbox" name="gdpr" id="js-gdpr">
                                    <span class="checkbox-label">Súhlasím so spracovaním údajov podľa  <a href="/dokument/ochrana-osobnych-udajovx" target="_blank" class="text-highlighted">pravidiel o ochrane osobných údajov.</a> </span>
                                </label>
                            </div>
                            <button class="btn btn-primary btn--big btn--full" @click.prevent="sendPhoneContact">
                                <span>Odoslať</span>
                            </button>
                        <br>
                    </div>
                    <Notification
                        v-if="successMessage"
                        type="success"
                        text="Dakujeme, budeme Vás kontaktovať"
                    ></Notification>
                    <!-- Vyber balikov -->
                    <div class="card-holder">
                        <!-- Main Offer item -->
                        <package-card
                            v-for="(defaultPackage,
                            index) in getDefaultPackagesUnderProgram(1)"
                            class="main-package"
                            :key="`defaultPackage${index}`"
                            :pckg="defaultPackage"
                            :checked="defaultPackage.additional == 0"
                            :omitFreeStreaming="true"
                            @input="checkInArray($event)"
                            disabled
                        >
                            <template v-slot:price v-if="promotion">
                                <div class="package-card__price">
                                    <span
                                        >už od
                                        {{
                                            getProgram(getSelectedAnnetaPackage)
                                                .promo.price
                                        }}€</span
                                    >
                                    <span>/mesiac</span>
                                </div>
                                <div class="offer__item__price__description">
                                    s DPH / mesačne
                                </div>

                                <small>
                                    Od
                                    {{
                                        parseInt(
                                            getProgram(getSelectedAnnetaPackage)
                                                .promo.months
                                        ) + 1
                                    }}. mesiaca {{ defaultPackage.price }}€
                                </small>
                            </template>

                            <template v-slot:disclaimer>
                                <!-- V pripade, ze je v sluzbe spustena promo akcia -->
                                <template v-if="isPromotionActive">
                                    <div class="offer__item__disclaimer">
                                        <span
                                            >Ak si objednáte službu na
                                            <span class="text-highlighted"
                                                >{{
                                                    getProgram(1).promo.months
                                                }}
                                                mesiacov</span
                                            >
                                            a viac, získate ju
                                            <span class="text-highlighted"
                                                >za
                                                {{ getProgram(1).promo.price }}€
                                                mesačne</span
                                            ></span
                                        >
                                    </div>
                                    <small>
                                        Zľavu je možné si uplatniť pokiaľ si
                                        aktivujete služby v dátume od
                                        {{ getProgram(1).promo.from }} do
                                        {{ getProgram(1).promo.to }}
                                    </small>
                                </template>
                            </template>
                        </package-card>
                        <!-- /Main Offer item -->

                        <package-card-disable
                            :key="`freePackage`"
                            :checked="true"
                            :items="freeChannels"
                            disabled
                        >
                        </package-card-disable>

                        <package-card
                            v-for="(additionalPackage,
                            index) in getAdditionalPackagesUnderProgram(1)"
                            :key="`additionalPackage${index}`"
                            :pckg="additionalPackage"
                            :checked="
                                service.some(
                                    srvc => srvc.id == additionalPackage.id
                                )
                            "
                            @input="checkInArray($event)"
                        >
                        </package-card>
                    </div>
                    <div class="mb-5 text-left">
                        <conditions :headlineCentered="true">
                            <template v-slot:headline>
                                <div class="text-center">
                                    Podmienky
                                </div>
                            </template>
                            <template v-slot:content>
                                <p>
                                    Zmluva sa uzatvára na dobu určitú v trvaní
                                    podľa počtu zvolených mesiacov. Zmluvu na
                                    dobu určitú nie je Užívateľ oprávnený
                                    vypovedať, ak nebolo dohodnuté alebo určené
                                    inak. V prípade predĺženia trvania Zmluvy na
                                    dobu neurčitú môže Užívateľ vypovedať Zmluvu
                                    z akéhokoľvek dôvodu alebo aj bez udania
                                    dôvodu. Výpovedná lehota je 1 mesiac, pričom
                                    začína plynúť prvým dňom kalendárneho
                                    mesiaca nasledujúceho po mesiaci, v ktorom
                                    bola písomná výpoveď doručená
                                    Poskytovateľovi. Poskytovateľom Služby
                                    Plustelka je spoločnosť Towercom, a.s., so
                                    sídlom Cesta na Kamzík 14, 831 01
                                    Bratislava, zap. v OR OS Bratislava I,
                                    oddiel Sa, vl. č.: 3885/B, IČO: 36 364 568
                                    (ďalej len „Poskytovateľ“). Služba Plustelka
                                    je poskytovaná na základe Zmluvy o
                                    poskytovaní Služby, ktorej neoddeliteľnou
                                    súčasťou sú
                                    <a
                                        href="/dokument/vseobecne-obchodne-podmienky"
                                        target="_blank"
                                        >Všeobecné podmienky</a
                                    >
                                    poskytovania služieb spoločnosti Towercom,
                                    a.s. (ďalej len „VOP“).
                                    <a
                                        href="/dokument/informacie-pre-spotrebitela"
                                        target="_blank"
                                        >Základné informácie pre Vás ako
                                        spotrebiteľa</a
                                    >. Pojmy označené s veľkým začiatočným
                                    písmenom, ktoré sú definované vo VOP a nie
                                    sú výslovne definované v tomto formulári,
                                    majú v tomto formulári ten istý význam.
                                </p>
                            </template>
                        </conditions>
                    </div>
                </div>
                <div class="col-lg-5 pl-lg-4">
                    <div class="card-wrapper">
                        <div class="card">
                            <div class="card-header">
                                <h2 class="h4 card-header__title">
                                    Zvoľte si počet mesiacov <br />využívania
                                    služby
                                </h2>
                            </div>
                            <!-- Counter -->
                            <div class="card-item">
                                <div class="card-quantity mb-3">
                                    <div class="card-quantity__price">
                                        <span class="total">{{ counterMonthlyFromTotal }} €</span>
                                        <!--                                        <span class="total">{{ counterMonthlyForSixMonthsAction }} €</span>-->
                                        <span>/mesiac</span>
                                    </div>
                                </div>
<!--                                <div style="-->
<!--                                    text-align: center;-->
<!--                                    font-weight: bold;-->
<!--                                    font-size: 1.5rem;-->
<!--                                    color: #11AA87;-->
<!--                                    margin-bottom: .3125rem;-->
<!--                                ">Získajte až 2 mesiace zadarmo</div>-->

                                <div class="card-quantity">
                                    <p class="mt-3 mb-4 text-smaller">
<!--                                        Vyber si 3, 6 alebo 12 mesačné predplatné a získaj zľavu-->
                                    </p>
<!--                                    <p class="mt-3 mb-4 text-smaller">-->
<!--                                        Užite si dva mesiace Plustelky zadarmo.-->
<!--                                        Predplaťte si teraz Plustelku Štandard-->
<!--                                        na rok v našom e-shope a každý nový zákazník automaticky-->
<!--                                        získava voucher na ďalší extra mesiac-->
<!--                                        balíka Plustelka Štandard zadarmo.-->
<!--                                        Užite si tak 13 mesiacov zábavy s Plustelkou. <strong>Akcia platí do 30.9.2023</strong>-->
<!--                                    </p>-->
                                </div>

                                <div style="
                                    text-align: center;
                                    font-weight: bold;
                                    font-size: 1.5rem;
                                    color: #11AA87;
                                    margin-bottom: .3125rem;
                                ">
                                    Získajte výhodné predplatné na 12 mesiacov
                                    <!-- 6 mesacna akcia -->
                                    <!-- Získajte výhodné predplatné na 6 mesiacov-->
                                </div>

                                <div class="card-quantity">
                                    <!-- 6 mesacna akcia -->
<!--                                    <p class="mt-3 mb-4 text-smaller">-->
<!--                                        Predplaťte si Plustelku Štandard na 6 mesiacov-->
<!--                                        a získajte našu službu za zvýhodnenú-->
<!--                                        cenu 6,49€ mesačne. <strong>Akcia platí do 31. mája 2024</strong>-->
<!--                                    </p>-->
                                    <p class="mt-3 mb-4 text-smaller">
                                        Predplaťte si Plustelku Štandard na 11 mesiacov a získajte 1 mesiac služby zadarmo. <strong>Akcia platí do odvolania.</strong>
                                    </p>
                                    <div class="card-quantity__box">
                                        <button
                                            @click.prevent="
                                                increment('subtract')
                                            "
                                            class="card-quantity__button card-quantity__button--minus"
                                        ></button>
                                        <div class="card-quantity__input-group">
                                            <input
                                                class="card-quantity__input"
                                                @click.prevent
                                                type="text"
                                                v-model="bindedMonths"
                                                min="1"
                                            />
                                            <span
                                                class="card-quantity__placeholder"
                                                >mesiacov</span
                                            >
                                        </div>
                                        <button
                                            class="card-quantity__button card-quantity__button--plus"
                                            @click.prevent="increment('add')"
                                        ></button>
                                    </div>
                                    <!-- AKCIA: 3,6,12 zlavy -->
<!--                                     <notification-->
<!--                                         v-if="[2,5,11].includes(bindedMonths)"-->
<!--                                         text="Objednajte si ešte jeden mesiac a získajte zľavu!"-->
<!--                                         type="warning"-->
<!--                                     ></notification>-->
<!--                                    <notification-->
<!--                                        v-if="[3,6,12].includes(bindedMonths)"-->
<!--                                        text="Super, získali ste zľavu! "-->
<!--                                        type="success"-->
<!--                                    ></notification>-->

                                    <!-- 6 mesacna akcia -->
<!--                                    <notification-->
<!--                                        v-if="bindedMonths === 5"-->
<!--                                        text="Objednajte si ešte jeden mesiac a získate 6 mesačné zvýhodnené predplatné!"-->
<!--                                        type="warning"-->
<!--                                    ></notification>-->
<!--                                    <notification-->
<!--                                        v-if="bindedMonths === 6"-->
<!--                                        text="Super, získavate od nás 6 mesačné zvýhodnené predplatné!"-->
<!--                                        type="success"-->
<!--                                    ></notification>-->

<!--                                    AKCIA: 12ty mesiac zadarmo -->
                                    <notification
                                        v-if="bindedMonths === 11"
                                        text="Objednajte si ešte jeden mesiac a máte ho ZADARMO!"
                                        type="warning"
                                    ></notification>
                                    <notification
                                        v-if="bindedMonths === 12"
                                        text="Výborne, získali ste mesiac služby zadarmo!"
                                        type="success"
                                    ></notification>
<!--                                    AKCIA: 3,6,12 zlavy -->
<!--                                    <p class="mt-3 mb-4 text-smaller text-center">-->
<!--                                        Vyber si 3, 6 alebo 12 mesačné predplatné a získaj zľavu-->
<!--                                    </p>-->
                                </div>
                            </div>
                            <div class="card-item">
                                <div class="card-item-inner">
                                    <div class="card-item-inner__content">
                                        <h3 class="h5">Spolu:</h3>
                                    </div>
                                    <div class="card-item-inner__price">
                                        <span class="total"
                                            >
<!--                                            {{ counterTotalForSixMonthsAction }}€-->
                                            {{ newCounterTotal }}€
                                        </span
                                        >
                                        <span
                                            >na {{ bindedMonths }}
                                            {{
                                                monthsDeclension(bindedMonths)
                                            }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- End Counter -->
                        </div>
                        <button
                            class="btn btn-primary btn--big btn--full mt-3"
                            :disabled="!stepFilledCorrectly"
                            @click="nextStep"
                        >
                            {{ nextBtnContent }}
                            <img
                                src="/img/icons/arrow-right.svg"
                                alt=""
                                class="btn__icon"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import packageCardDisable from "../../components/vue/ponuka/package-card__antena_disable.vue";
import packageCard from "../../components/vue/ponuka/package-card__antena.vue";
import conditions from "../../components/vue/conditions/conditions.vue";
import { mapGetters } from "vuex";
import _orderBy from "lodash/orderby";

//Mixins
import formStep from "../../mixins/formStep";
import Antena from "../../components/vue/Antena";
import ConfirmLocation from "../../components/vue/ConfirmLocation";
import Notification from "../../components/vue/notification/Notification";

export default {
    mixins: [formStep],

    data() {
        return {
            stepName: "vyberBalikov",
            prevStepName: "antenaStep1",
            nextStepName: "antenaStep2",
            cart: [],
            mainService: [],
            service: [],
            freeChannels: [],
            bindedMonths: 1,
            selectedCity: {},
            promotion: true,
            locationSuccess: false,
            showForm: false,
            successMessage: false,
        };
    },

    components: {
        Notification,
        packageCard,
        conditions,
        ConfirmLocation,
        packageCardDisable
    },

    watch: {
        bindedMonths(newValue, oldValue) {
            // Nasilu zmeni service array najprv na nic a potom na stav ktory bol pred tym
            // je to len vynuteny refresh stavu aby sa spustil watcher na service
            // totizto zmenou poctu mesiacov si moze zakaznik zmenit cenu toho co uz ma v kosiku (zakladny balik)
            const backup = this.service;
            this.service = [];
            this.service = backup;

            if (newValue > 12) {
                this.bindedMonths = 12;
            }
            if (newValue < 1) {
                this.bindedMonths = 1;
            }
        },

        // Sledujeme polozku additional services, a podla nej pridame polozky
        // do arrayu cart z ktore sa na konci vypocita vysledna cena
        service: {
            handler(value) {
                this.cart = [];
                //console.log('service changed')
                value.forEach(service => {
                    // console.log(service);

                    let desc =
                        service.additional == 1
                            ? "doplnkový balík"
                            : "základný balík";
                    let finalPrice = service.price;

                    // Ak ide o zakladny balik a su splnene podmienky
                    // na aplikaciu zlavy (pocet mesiacov objednanej sluzby
                    // je rovny alebo vacsi ako dlzka stanovena promo akciou)
                    // tak zmen cenu zakladneho baliku a aplikuj tu ktora je uvedena v akcii
                    if (
                        service.additional == 0 &&
                        this.isPromotionActive &&
                        this.bindedMonths >= this.counterMonthsRequired
                    ) {
                        finalPrice = this.counterMonthly;
                    }

                    let accountedService = {
                        title: service.name,
                        price: parseFloat(finalPrice),
                        payment: "PM",
                        description: desc
                    };

                    //accountedService.discount = service.discount;

                    this.cart.push(accountedService);
                });
            },
            deep: true
        }
    },

    computed: {
        ...mapGetters([
            "getStep",
            "getStepsState",
            "getProgram",
            "getSelectedProgram",
            "getDevicesUnderProgram",
            "getAntennasUnderProgram",
            "getAvailablePrograms",
            "getAdditionalPackages",
            "getAdditionalPackagesUnderProgram",
            "getDefaultPackages",
            "getDefaultPackagesUnderProgram"
        ]),

        isPromotionActive() {
            if (this.getProgram(1).promo != null) {
                return true;
            } else {
                return false;
            }
        },

        freeStreamingChannels() {
            const defaultPackage = this.getDefaultPackagesUnderProgram(1)[0];
            let freeStreamingChannels = defaultPackage.channels.filter(
                ar => ar.free_streaming == true
            );
            freeStreamingChannels = _orderBy(
                freeStreamingChannels,
                ["order"],
                ["asc"]
            );

            return freeStreamingChannels;
        },

        nextBtnContent() {
            if (this.stepFilledCorrectly) return "Ďalší krok";
            else return "Prosím overte svoju lokalitu";
        },
        stepFilledCorrectly() {
            return (
                // Bool
                // tu zhromazdujeme vsetky podmienky, ktore musia byt
                // splnene na to, aby formular mohol pokracovat dalej
                this.service.length,
                this.isDefaultInSelection,
                this.locationSuccess
            );
        },
        currentStepStateToSave() {
            //Nastavenia, ktore sa maju ulozit do globalneho stavu
            return {
                step: "vyberBalikov",
                state: {
                    service: this.service,
                    cart: this.cart,
                    bindedMonths: this.bindedMonths
                }
            };
        },

        getDevicesInPromotion() {
            let allDevices = [];
            const devices = this.getDevicesUnderProgram(1);
            const antennas = this.getAntennasUnderProgram(1);
            Antena.setDisplayAntennas(antennas.length > 0);

            allDevices = allDevices.concat(devices);
            allDevices = allDevices.concat(antennas);

            //console.log(allDevices);

            allDevices.map(device => {
                device.selectedPrice = device.prices.filter(price => {
                    return price.program == this.getProgram(1).name;
                });
            });

            const promotedDevices = allDevices.filter(device => {
                return device.selectedPrice[0].discounts != null;
            });

            return promotedDevices;
        },

        isDefaultInSelection() {
            const defaultPackage = this.getDefaultPackagesUnderProgram(1);
            return this.service.includes(defaultPackage[0]);
        },

        counterTotal() {
            if (this.bindedMonths >= 12) {
                return parseFloat(11 * this.counterMonthly).toFixed(2);
            } else {
                return parseFloat(
                    this.bindedMonths * this.counterMonthly
                ).toFixed(2);
            }
        },

        counterTotalForSixMonthsAction() {
            if (this.bindedMonths === 6) {
                return parseFloat(6 * (parseFloat(this.counterMonthly) - 0.5)).toFixed(2);
            } else {
                return parseFloat(
                    this.bindedMonths * this.counterMonthly
                ).toFixed(2);
            }
        },

        counterMonthlyForSixMonthsAction() {
            if (parseInt(this.bindedMonths) === 6) {
                return parseFloat(parseFloat(this.counterMonthly) - 0.5).toFixed(2);
            } else {
                return parseFloat(this.counterMonthly).toFixed(2);
            }
        },

        newCounterTotal() {
            let result = this.counterTotalDefaultPackage + this.counterTotalExtraItemsCost;
            return parseFloat(result).toFixed(2);
        },

        newCounterMonthly() {
            if (this.bindedMonths >= 11) {
                return parseFloat(this.counterMonthly - 1).toFixed(2);
            } else {
                return parseFloat(this.counterMonthly).toFixed(2);
            }
        },

        counterTotalDefaultPackage() {
            const defaultPackage = this.getDefaultPackagesUnderProgram(1)[0]; // Toto najde defaultny balicek pod IDckom Anteny
            const service = this.getProgram(1); // Toto najde Sluzbu Antena v ktorej sa uklada promo akcia
            let result = 0.0;

            result = parseFloat(defaultPackage.price);

            // AKCIA: 3,6,12 zlava
            // if (this.bindedMonths == 3) {
            //     // 5% zlava
            //     return parseFloat(6.65) * parseFloat(this.bindedMonths);
            // }
            // else if (this.bindedMonths == 6) {
            //     // 7% zlava
            //     return parseFloat(6.49) * parseFloat(this.bindedMonths);
            // }
            // else if (this.bindedMonths >= 12) {
            //     // 10% zlava
            //     return parseFloat(6.29) * parseFloat(this.bindedMonths);
            // }
            // AKCIA: 12ty mesiac zadarmo
            if (this.bindedMonths >= 12) {
                return parseFloat(11 * result);
            }
            // AKCIA: pre 6ty mesiac
            // else if (this.bindedMonths === 6)
            // {
            //     return parseFloat(6 * (result - 0.5));
            // }
            else {
                return parseFloat(this.bindedMonths * result);
            }
        },

        counterTotalExtraItemsCost() {
            let extraItemsCost = 0.0;
            if (this.cart.length > 1) {
                for (let i = 1; i < this.cart.length; i++) {
                    // if (this.bindedMonths >= 12) {
                    //     if (this.cart[i].title === "Plustelka Premium") {
                    //         extraItemsCost += (this.cart[i].price * 0.5);
                    //     }
                    //     else {
                    //         extraItemsCost += this.cart[i].price;
                    //     }
                    // }
                    // else {
                        extraItemsCost += this.cart[i].price;
                    // }
                }
            }

            return parseFloat(extraItemsCost * this.bindedMonths);
        },

        counterMonthlyFromTotal() {
            let result = parseFloat(this.counterTotalDefaultPackage + this.counterTotalExtraItemsCost);
            result = result / parseFloat(this.bindedMonths);

            return parseFloat(result).toFixed(2);
        },

        counterMonthly() {
            const defaultPackage = this.getDefaultPackagesUnderProgram(1)[0]; // Toto najde defaultny balicek pod IDckom Anteny
            const service = this.getProgram(1); // Toto najde Sluzbu Antena v ktorej sa uklada promo akcia
            let result = 0.0;
            let extraItemsCost = 0;
            if (this.cart.length > 1) {
                for (let i = 1; i < this.cart.length; i++) {
                    extraItemsCost += this.cart[i].price;
                }
            }
            // if (
            //     this.bindedMonths >= this.counterMonthsRequired &&
            //     this.isPromotionActive
            // ) {
            //     result = (parseFloat(defaultPackage.price) * 11/12).toFixed(2)//parseFloat(service.promo.price).toFixed(2);
            // } else {
            result = parseFloat(defaultPackage.price).toFixed(2);
            // }
            return parseFloat(result) + extraItemsCost;
        },
        counterDiscountPrice() {
            if (this.isPromotionActive) {
                return this.getProgram(1).promo.price;
            } else return false;
        },
        counterMonthsRequired() {
            if (this.isPromotionActive) {
                return this.getProgram(1).promo.months;
            } else return false;
        },
    },

    methods: {
        increment(instruction) {
            if (instruction == "add" && this.bindedMonths <= 12) {
                this.bindedMonths++;
            }

            if (instruction == "subtract" && this.bindedMonths > 1) {
                this.bindedMonths--;
            }
        },

        monthsDeclension(months) {
            // Sklonovanie slova mesiac
            if (months == 1) {
                return "mesiac";
            } else if (months >= 2 && months < 5) {
                return "mesiace";
            } else if (months >= 5) {
                return "mesiacov";
            }
        },

        checkInArray(evt) {
            const doesArrayContainValue = this.service.some(
                arr => arr.id == evt.value.id
            );

            if (evt.checked == true) {
                if (!doesArrayContainValue) {
                    this.service.push(evt.value);
                }
            }

            if (evt.checked == false) {
                if (doesArrayContainValue) {
                    this.service = this.service.filter(arr => {
                        return evt.value.id != arr.id;
                    });
                }
            }
        },

        sendPhoneContact() {
            let phone = document.getElementById('js-phone').value;
            let gdpr = document.getElementById('js-gdpr').value;
            // if (gdpr.)
            let formData = new FormData();
            formData.set('phone', phone);
            formData.set('gdpr', gdpr);
            formData.set('place', this.selectedCity.city);
            this.$http.post('/api/custom-helper/submit-place', formData).then(
                data => {
                    if(data.data) {
                        this.showForm = false;
                        this.successMessage = true;
                    }
                }
            )
        }
    },

    mounted() {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        if(urlParams.has('mesiace')){
            if(parseInt(urlParams.get('mesiace')) === 12){
                this.bindedMonths = 12;
            }
            if(parseInt(urlParams.get('mesiace')) ===  6){
                this.bindedMonths = 6;
            }
        }

        this.$gtm.enable(true);
        this.selectedCity = JSON.parse(
            window.localStorage.getItem("selectedCity")
        );
        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        const stepData = this.getStep("vyberBalikov");
        if (stepData) {
            this.service = stepData.service;
            this.bindedMonths = stepData.bindedMonths;
        }

        // Skontroluje, ci je medzi services pridany zakladny balik,
        // ak nieje, tak ho tam nasilu pridame
        const defaultPackage = this.getDefaultPackagesUnderProgram(1)[0];

        // Skontroluje
        if (!this.isDefaultInSelection) {
            this.service.push(defaultPackage);
        }

        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'actionField': { 'step':1},
                'checkout': {
                    'products': [],
                }
            },
        });

        this.freeChannels = this.freeStreamingChannels;
        console.log(defaultPackage);
        console.log(this.freeChannels);
        console.log(this.freeStreamingChannels);
    }
};
</script>

<style lang="scss">
.free-streaming {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 100%;

    text-align: center;

    @media (min-width: 768px) {
        text-align: left;
    }

    h3 {
        font-weight: bold;
        font-size: 1.5rem;
    }

    &__channels {
        display: inline-grid;
        margin-top: 1rem;

        grid-gap: 1.25rem;
        grid-template-columns: repeat(auto-fit, minmax(auto, 4em));

        justify-content: center;

        @media (min-width: 768px) {
            justify-content: flex-start;
        }

        img {
            display: inline-flex;
            //width: 100%;
            width: 100%;
            height: 100%;
            max-width: 4em;
            max-height: 2em;
            object-fit: contain;

            @media (min-width: 768px) {
                width: auto;
            }
        }
    }
}

.offer__wrapper {
    display: flex;
    // grid-gap: 2rem;
    // grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    .package {
        flex: 1 0 100%;
        max-width: 25rem;
        display: flex;

        &.main-package {
            max-width: 80rem;
        }
    }

    .offer__item {
        margin: 1rem;

        @media (max-width: 768px) {
            margin: 1rem 0;
        }
    }
}
</style>
